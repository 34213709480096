import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <div className="py-12 md:py-16">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">

          {/* Top area: Blocks */}
          <div className="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">

            {/* 1st block */}
            <div className="md:col-span-4 lg:col-span-5">
              <div className="mb-2">
                {/* Logo */}
                <Link to="/" className="inline-block" aria-label="Youblicity">
                  <svg width="40px" height="41px" viewBox="0 0 40 41" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g id="Logo" transform="translate(0.600000, 0.400000)">
                        <polygon id="Path" points="0.4 27.4 0.4 40.8 0.6 40.8 0.6 27.4"></polygon>
                        <path d="M0.6,0.8 C0.5,0.5 0.4,0.3 0.4,0 L0.4,20.5 C0.4,20.2 0.4,19.9 0.6,19.6 L0.6,0.8 Z" id="Path"></path>
                        <path d="M1.9,18.2 L19.1,11.1 C19.7,10.9 20.3,10.7 20.8,10.5 C21.4,10.3 22,10.2 22.6,10 C22,9.8 21.4,9.6 20.8,9.4 C20.2,9.2 19.6,9 19,8.7 L1.9,2.2 C1.5,2 1.1,1.7 0.8,1.3 C0.7,1.2 0.6,1 0.6,0.8 L0.6,19.5 C0.7,19.3 0.8,19.1 0.9,18.9 C1.1,18.6 1.5,18.4 1.9,18.2 Z" id="Path" fill="#9D47DA" fillRule="nonzero"></path>
                        <polygon id="Path" fill="#9D47DA" fillRule="nonzero" points="31.1 14 0.6 27.4 0.6 40.8 0.6 40.8 40 18.2"></polygon>
                    </g>
                  </svg>
                </Link>
              </div>
              <div className="text-gray-400">youblicity hilft Marken dabei, ihre Dienstleistungen und Produkte nicht-intrusiv über Livestreams zu bewerben, was den Streamern die Möglichkeit gibt, ihre Kanäle zu monetarisieren, ohne die Erfahrung ihrer Zuschauer zu beeinträchtigen.<br/>
youblicity arbeitet direkt mit den Urhebern der Inhalte zusammen und fordert diese auf sicherzustellen, dass sie in voller Übereinstimmung mit den Dienstleistungsbedingungen, dem Verhaltenskodex und den vertraglichen Verpflichtungen gegenüber den Plattformen arbeiten, auf denen sie Inhalte erstellen.<br/>
youblicity ist in keiner Weise mit Twitch, YouTube Gaming, Facebook Live oder Mixer verbunden oder wird von diesen unterstützt.</div>
            </div>

            {/* 2nd, 3rd and 4th blocks */}
            <div className="md:col-span-8 lg:col-span-7 grid sm:grid-cols-2 gap-4">

              {/* 2nd block */}
              <div className="text-sm">
                <h6 className="text-gray-200 font-medium mb-1">Links</h6>
                <ul>
                  <li className="mb-1">
                    <a href="https://app.youblicity.com/terms" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">AGBs</a>
                  </li>
                  <li className="mb-1">
                  <a href="https://app.youblicity.com/privacy" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">Datenschutz</a>
                  </li>
                  <li className="mb-1">
                    <a href="https:/app.youblicity.com" target="_blank" rel="noreferrer" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">App</a>
                  </li>
                </ul>
              </div>

              {/* 3rd block */}
              <div className="text-sm">
                <h6 className="text-gray-200 font-medium mb-1">Company</h6>
                <div className="text-gray-400">
                <p>Müllerstraße 12a</p>
                <p>86153 Augsburg</p>
                <p>Deutschland</p>
                <br/>
                <p>Phone: +49 151 235 19604</p>
                <p>E-Mail: info@youblicity.com</p>
                <br/>
                <p>Registergericht: AG Augsburg</p>
                <p>Registernummer: 33597</p>
                <br/>
                <p>Vertretungsberechtigter Geschäftsführer: George Danzer</p>
                </div>
              </div>

            </div>

          </div>

          {/* Bottom area */}
          <div className="md:flex md:items-center md:justify-between">

            {/* Social links */}
            <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
              <li>
                <a href="https://twitter.com/_youblicity_/" className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out" aria-label="Twitter">
                  <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                  </svg>
                </a>
              </li>
              <li className="ml-4">
                <a href="https://www.linkedin.com/company/youblicity/mycompany/" className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out" aria-label="Linkedin">
                  <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.3 8H8.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V8.7c-.1-.4-.4-.7-.8-.7zM12.7 21.6h-2.3V14h2.4v7.6h-.1zM11.6 13c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V14h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z" />
                  </svg>
                </a>
              </li>
            </ul>

            {/* Copyrights note */}
            <div className="text-gray-400 text-sm mr-4">&copy; 2021 youblicity. All rights reserved.</div>

          </div>

        </div>
      </div>
    </footer>
  );
}

export default Footer;
