import React from 'react';

import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import CtaContact from '../partials/CtaContact';
import Footer from '../partials/Footer';

function Contact() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                <h1 className="h1 mb-4" data-aos="fade-up">Wie können wir helfen?</h1>
                <p className="text-xl text-gray-400" data-aos="fade-up" data-aos-delay="200">Wir bringen Brands und Streamer zusammen.</p>
              </div>

            </div>
          </div>
        </section>

        {/*  Bottom CTA */}
        <CtaContact />

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Contact;